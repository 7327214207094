@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.holder {
  align-items: center;
  justify-content: center;
  /* padding: 10px; */
}

.marquee {
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-bottom: 2px solid #fff; */
  /* height: 60px; */
  /* width: 280px; */
  overflow: hidden;
  white-space: nowrap;
  /* margin: 0 10px; */
}

.marquee--inner {
  display: block;
  width: 800%;
  position: absolute;
  animation: marquee linear;
  animation-duration: 20s;
  animation-delay: 5s;
  animation-iteration-count: infinite;
}

.marquee--inner span {
  float: left;
  width: 100%;
  overflow: hidden;
}

@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -720%;
  }
}


.swiper-button-prev,
.swiper-button-next {
  color: #fff !important;
}

.swiper-pagination-bullet-active{
  background: #fff !important;
}