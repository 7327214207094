/* iPhone horizontal scroll fix */
*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  /* this makes sure the padding and the border is included in the box sizing */
  box-sizing: border-box;
  /* overflow-y: hidden; */
}

html body {
  padding: 0;
  margin: 0;
  overflow-y: inherit;
  overflow-x: hidden;
}

body {
  width: 100%;
  font-family: "Poppins";
  /* background-color: #422006; */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #ffff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Select::placeholder {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.PhoneInput {
  /* height: 2.5rem; */
  padding: 0.5rem 0.5rem;
  margin-top: 3px;
  font-size: 1rem;
  line-height: 1.75rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.PhoneInputInput {
  background-color: #f8fafc;
}

.PhoneInput::placeholder {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.btnDefault {
  padding: 0 28px;
  border-style: solid;
  border-width: 2px;
  border-color: transparent;
  /* border-radius: 8px; */
  background-color: transparent;
  background: linear-gradient(
      95deg,
      rgba(0, 0, 0, 0.3) 15%,
      #6549d5 45%,
      #e33fa1 75%,
      #fb5343 100%
    )
    95% / 200% 100%;
  transition-duration: 300ms;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  text-shadow: rgba(0, 0, 0, 0.3);
  display: inline-block;
  height: 50px;
  line-height: 47px;
  letter-spacing: 0.5px;
  width: auto;
}

.react-dropdown-select-type-single span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 75%;
}

.react-dropdown-search-custom {
  display: flex;
  flex-direction: column;
}

